import { ErrorHandler, inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ObservableInput, take } from 'rxjs';
import { CrmMessageService } from 'common-module/message';
import { CrmTranslateMessage } from 'common-module/translate';
import { CrmDictionary, CrmResolvable } from 'common-module/core/types';
import { crmResolveExpression } from 'common-module/core';
import { HttpErrorResponse } from '@angular/common/http';

export const catchAndHandleErrorFactory = () => {
  const errorHandler = inject(ErrorHandler);
  const message = inject(CrmMessageService);

  return <T, O extends ObservableInput<T>>({
    $,
    errorMessage$,
  }: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    $: (err: any) => O;
    errorMessage$?: CrmResolvable<CrmTranslateMessage, any>;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }) => {
    return catchError<T, O>((err) => {
      errorHandler.handleError(err);

      if (errorMessage$) {
        const withDefaultContext = (ctx?: CrmDictionary) => {
          if (err instanceof HttpErrorResponse) {
            return { status: err.status, ...ctx };
          }

          return { status: 'n/a', ...ctx };
        };

        crmResolveExpression({ resolvable: errorMessage$, source: err })
          .pipe(take(1))
          .subscribe((errorMessage) => {
            if (typeof errorMessage === 'string') {
              message.error(errorMessage, withDefaultContext());
            } else {
              message.error(
                errorMessage.message,
                withDefaultContext(errorMessage.context),
              );
            }
          });
      }

      return $(err);
    });
  };
};
