import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { CrmEnvelope } from 'common-module/signature';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { EMPTY, map, Observable, of } from 'rxjs';

import { environment } from '~/environments/environment';
import { safeListDocumentsByIDs } from '~/shared/utils/list/safe-list';
import { createFormData } from '~/shared/utils/object/create-form-data';
import { catchAndHandleErrorFactory } from '~/shared/utils/error/catch-and-handle-error.factory';

import { DocumentModel } from './document.model';

export interface DocumentEnvelopeBody {
  device: string;
}

@Injectable({ providedIn: 'root' })
export class DocumentsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'documents',
  })
  private readonly endpoint!: CrmEndpoint<DocumentModel>;

  private readonly catchAndHandleError = catchAndHandleErrorFactory();

  list<Params>(params: Params) {
    return this.endpoint.list({
      params,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  listData<Params>(params: Params) {
    return this.list(params).pipe(map(({ data }) => data));
  }

  listAll<Params>(params: Params) {
    return this.endpoint.listAll({
      params,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  resolveFiles(files?: string[] | DocumentModel[]) {
    if (!files) {
      return of([]);
    }

    if (typeof files?.[0] === 'string') {
      return safeListDocumentsByIDs(this, files as string[]);
    }

    return of(files as DocumentModel[]);
  }

  update<Body>(id: string, body: Body) {
    return this.endpoint.update(id, body);
  }

  getResourceUrl(value: string): string {
    return `${environment.crmUrl}/documents/${value}/resource`;
  }

  openResource(id: string): void {
    this.openDocument(`${id}/resource`);
  }

  uploadDocument(file: File): Observable<DocumentModel> {
    const data = new FormData();
    data.append('file', file);

    return this.endpoint.create(data);
  }

  upload(file: NzUploadFile, params?: CrmDictionary) {
    const body = createFormData(params);
    body.append('file', file as unknown as Blob);

    return this.endpoint.create(body);
  }

  resource(id: string) {
    return this.endpoint
      .request<Blob>('GET', [id, 'resource'].join('/'), {
        responseType: 'blob',
      })
      .pipe(
        this.catchAndHandleError({
          $: (err: HttpErrorResponse) => {
            if (isDevMode() && err.status === 0) {
              return of(new Blob(['dev CORS error - fallback content']));
            }

            return EMPTY;
          },
          errorMessage$: {
            message: 'errors.documents.resource',
            context: { id },
          },
        }),
      );
  }

  overwrite(
    id: string,
    file: NzUploadFile,
    params?: CrmDictionary,
  ): Observable<DocumentModel> {
    const body = createFormData(params);
    body.append('file', file as unknown as Blob);

    return this.endpoint.update(id, body);
  }

  createEnvelope<Body>(id: string, body: Body): Observable<CrmEnvelope> {
    return this.endpoint.request('POST', [id, 'envelope'].join('/'), { body });
  }

  private openDocument(url: string): void {
    window.open(`${environment.crmUrl}/documents/${url}`, '_blank');
  }
}
