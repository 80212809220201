import { Component, inject, input, OnInit, TemplateRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CrmControlValueAccessorDirective,
  crmControlValueAccessorFactory,
} from 'common-module/core';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { NzInputModule } from 'ng-zorro-antd/input';
import { CrmTranslateMessage, CrmTranslatePipe } from 'common-module/translate';
import { NgxMaskDirective } from 'ngx-mask';
import { toSignal } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';

import { FormatterService } from '../../../services/formatter.service';

@Component({
  standalone: true,
  selector: 'app-number',
  template: `
    <nz-input-group
      style="width: 100%"
      [nzPrefix]="prefix()"
      [nzSuffix]="suffix()"
      [nzStatus]="status()"
      [nzCompact]="compact()"
      [nzSize]="size()"
    >
      <input
        #inputField
        type="text"
        nz-input
        [formControl]="control"
        [mask]="'separator.' + precision()"
        [decimalMarker]="decimalMarker()"
        [thousandSeparator]="' '"
        [leadZero]="leadZero()"
        [nzStatus]="status()"
        [placeholder]="placeholder() | crmTranslate"
        [outputTransformFn]="outputTransformFn"
      />
    </nz-input-group>
  `,
  imports: [
    CrmTranslatePipe,
    NzInputModule,
    NgxMaskDirective,
    ReactiveFormsModule,
  ],
  providers: [crmControlValueAccessorFactory(NumberComponent)],
})
export class NumberComponent
  extends CrmControlValueAccessorDirective<number>
  implements OnInit
{
  prefix = input<string | TemplateRef<void>>();
  suffix = input<string | TemplateRef<void>>();
  placeholder = input<CrmTranslateMessage>();
  disable = input(false);

  size = input<NzSizeLDSType>('large');
  compact = input(true);
  precision = input<number>(2);
  leadZero = input(true);

  protected override defaultValue = 0;

  private formatter = inject(FormatterService);

  protected decimalMarker = toSignal(
    this.formatter.decimalMarker$.pipe(take(1)),
    { requireSync: true },
  );

  protected outputTransformFn = (value: unknown): unknown => {
    if (typeof value === 'string' && value.length) {
      return Number(value);
    }

    if (typeof value === 'number') {
      return value;
    }

    return undefined;
  };
}
