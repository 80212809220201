import { DecimalPipe } from '@angular/common';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';
import { CrmAuthModule, CrmInterceptHandlerToken } from 'common-module/auth';
import {
  CrmChangelogModalTriggerToken,
  CrmChangelogModule,
} from 'common-module/changelog';
import { CrmAppConfig } from 'common-module/core';
import { CrmEditorModule } from 'common-module/editor';
import { CrmEndpointModule } from 'common-module/endpoint';
import { CrmMessageModule } from 'common-module/message';
import { CrmModalModule } from 'common-module/modal';
import { crmProvideTranslate } from 'common-module/translate';
import { CrmUserService } from 'common-module/user';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { en_US, NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { crmProvideLayout } from 'common-module/layout-v2';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { UserApiService } from '~/api/user/user-api.service';
import { authConfig } from '~/config/auth.config';
import { endpointConfig } from '~/config/endpoint.config';
import { translateConfig } from '~/config/translate.config';
import { InterceptHandlerProvider } from '~/crm/auth/intercept-handler.provider';
import { ChangelogModalTrigger } from '~/crm/changelog/changelog-modal.trigger';
import { StreamModule } from '~/shared/modules/stream/stream.module';
import { TrackingModule } from '~/shared/modules/tracking/tracking.module';
import { TitleService } from '~/shared/services/title.service';
import { layoutConfig } from '~/config/layout.config';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

window.navigator?.serviceWorker?.getRegistrations()?.then((registrations) => {
  (registrations ?? [])
    .filter((r) => !r?.scope?.endsWith?.('stream-saver'))
    .forEach((r) => r?.unregister?.());
});

bootstrapApplication(AppComponent, {
  providers: [
    // NG
    provideAnimations(),
    provideRouter(appRoutes),

    // Modules
    importProvidersFrom(
      CrmAuthModule.forRoot(authConfig),
      CrmEndpointModule.forRoot(endpointConfig),
      CrmChangelogModule.forRoot({ changelogModal: true }),
      CrmEditorModule.forRoot(),
      CrmMessageModule.forRoot(),
      CrmModalModule.forRoot(),

      TrackingModule.forRoot(),
      StreamModule,

      NzDrawerModule,
    ),

    // Deps
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    { provide: NZ_I18N, useValue: en_US },
    provideEnvironmentNgxMask({}),

    // SWP
    crmProvideLayout(layoutConfig),
    crmProvideTranslate(translateConfig),
    { provide: CrmUserService, useExisting: UserApiService },
    { provide: CrmAppConfig, useValue: appConfig },
    { provide: CrmInterceptHandlerToken, useClass: InterceptHandlerProvider },
    { provide: CrmChangelogModalTriggerToken, useClass: ChangelogModalTrigger },

    // APP
    { provide: TitleStrategy, useExisting: TitleService },
    DecimalPipe,
  ],
}).catch((err: Error) => console.error(err));
