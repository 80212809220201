import { DecimalPipe } from '@angular/common';
import { computed, inject, Injectable, Signal } from '@angular/core';
import {
  CrmLocaleServiceToken,
  CrmTranslateService,
} from 'common-module/translate';
import { format, isSameDay, isValid } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DateInput,
  getDate,
  TIME_FORMAT,
} from '../utils/date/date-format';

import { CurrentClinicService } from './current-clinic.service';

export interface BaseFormatNumberOptions {
  value?: number;
  digits?: string;
  locale?: string;
  transformer?: (value: string) => string;
}

export interface FormatNumberOptions extends BaseFormatNumberOptions {
  append?: string;
}

@Injectable({ providedIn: 'root' })
export class FormatterService {
  private decimal = inject(DecimalPipe);
  private localeService = inject(CrmLocaleServiceToken);
  private translate = inject(CrmTranslateService);
  private currentClinic = inject(CurrentClinicService);

  readonly locale$ = this.translate
    .ready$()
    .pipe(map(() => this.translate.getActiveLang()));

  readonly locale = toSignal(this.locale$, { requireSync: true });

  readonly decimalMarker$: Observable<'.' | ','> = this.locale$.pipe(
    map((locale) => {
      switch (locale) {
        case 'sk':
        case 'cs':
          return ',';
        default:
          return '.';
      }
    }),
  );

  public formatDateInput(
    value: DateInput | undefined,
    _format: string,
  ): string {
    if (!value) {
      return '-';
    }

    const _value = getDate(value);
    const locales = this.localeService.config.locales;
    const locale = locales?.[this.localeService.currentLocale()]?.dateFnsLocale;
    return _value && isValid(_value)
      ? format(_value, _format, { locale })
      : '-';
  }

  public formatRange(start: DateInput, end: DateInput): string {
    const _start = getDate(start);
    const _end = getDate(end);
    let formatEnd = TIME_FORMAT;
    if (_start && _end && !isSameDay(_start, _end)) {
      formatEnd = DATE_TIME_FORMAT;
    }
    return `${this.formatDateTime(start)} - ${this.formatDateInput(
      end,
      formatEnd,
    )}`;
  }

  public formatDate(value?: DateInput): string {
    return this.formatDateInput(value, DATE_FORMAT);
  }

  public formatTime(value?: DateInput): string {
    return this.formatDateInput(value, TIME_FORMAT);
  }

  public formatDateTime(value?: DateInput): string {
    return this.formatDateInput(value, DATE_TIME_FORMAT);
  }

  public formatNumber(options: FormatNumberOptions): string {
    const {
      value = 0,
      append = '',
      digits = '1.0-2',
      locale = this.localeService.currentLocale(),
      transformer = (val: string) => val,
    } = options ?? {};
    const formatted = this.decimal.transform(value, digits, locale);
    return transformer(`${formatted}${append}`);
  }

  public formatCurrency(options: BaseFormatNumberOptions): string {
    const { digits = '1.2-2' } = options ?? {};
    return this.formatNumber({
      ...options,
      digits,
      append: this.currentClinic.currencySymbol(),
    });
  }

  public computeCurrency(signal: Signal<number | undefined>) {
    return computed(() =>
      this.formatCurrency({ value: signal(), locale: this.locale() }),
    );
  }

  public formatPercent(options: BaseFormatNumberOptions): string {
    return this.formatNumber({ ...options, append: '%' });
  }

  public computePercent(signal: Signal<number | undefined>) {
    return computed(() =>
      this.formatPercent({ value: signal(), locale: this.locale() }),
    );
  }

  public formatPhone(value?: string): string {
    if (!value) {
      return '-';
    }

    try {
      const parsed = parsePhoneNumberFromString(value);
      return parsed?.formatInternational() ?? '';
    } catch {
      return value;
    }
  }

  public parseNumber(value: string): string {
    return value.replace(/,/g, '.');
  }
}
