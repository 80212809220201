import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CrmFormDataInputComponent,
  CrmFormDataWrapperComponent,
} from 'common-module/form/fragments';
import { CrmSignalType } from 'common-module/core/types';

import { NumberComponent } from './number.component';

type NumberPickOptions = Pick<
  NumberComponent,
  | 'prefix'
  | 'suffix'
  | 'compact'
  | 'placeholder'
  | 'size'
  | 'precision'
  | 'leadZero'
>;

export type FormNumberOptions = {
  [Key in keyof NumberPickOptions]?: CrmSignalType<NumberPickOptions[Key]>;
};

@Component({
  standalone: true,
  selector: 'app-form-number',
  template: `
    <div class="crm-form-number">
      <crm-form-data-input-wrapper
        [status]="status$ | async"
        [error]="error$ | async"
        [config]="config"
      >
        <app-number
          [formControl]="control"
          [prefix]="options.prefix"
          [suffix]="options.suffix"
          [placeholder]="options.placeholder"
          [compact]="options.compact ?? true"
          [size]="options.size ?? 'large'"
          [precision]="options.precision ?? 2"
          [leadZero]="options.leadZero ?? true"
        ></app-number>
      </crm-form-data-input-wrapper>
    </div>
  `,
  imports: [
    ReactiveFormsModule,
    CrmFormDataWrapperComponent,
    NumberComponent,
    AsyncPipe,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FormNumberComponent extends CrmFormDataInputComponent {
  @HostBinding('class') protected readonly hostClass = 'crm-form-number';

  options: FormNumberOptions = {};
}
