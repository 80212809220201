import { FormGroup } from '@angular/forms';
import {
  CrmFormDataInputConfig,
  CrmFormFragment,
  CrmFormFragmentLayout,
} from 'common-module/form/fragments';
import {
  CrmFormCheckboxComponent,
  CrmFormCheckboxGroupComponent,
  CrmFormCheckboxGroupOptions,
  CrmFormCheckboxOptions,
  CrmFormDateComponent,
  CrmFormDateOptions,
  CrmFormInputComponent,
  CrmFormInputOptions,
  CrmFormSelectComponent,
  CrmFormSelectOptions,
  CrmFormTextareaComponent,
  CrmFormTextareaOptions,
} from 'common-module/form/inputs';
import { CrmFormGalleryComponent } from 'common-module/gallery';
import { Observable } from 'rxjs';

import {
  FormNumberComponent,
  FormNumberOptions,
} from '../../components/inputs/number/form-number.component';

export type FormInputOptions<Options> = {
  config?: CrmFormDataInputConfig;
  options?: Options;
  layout?: CrmFormFragmentLayout;
  visible$?: (form: FormGroup) => Observable<boolean>;
};

export const formDataInput = (
  name: string,
  options: FormInputOptions<CrmFormInputOptions>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormInputComponent,
  contentContext: {
    config: options.config,
    options: {
      placeholder: options.config?.label,
      ...options.options,
    },
  } as Partial<CrmFormInputComponent>,
  visible$: options.visible$,
  layout: options.layout,
});

export const formDataNumber = (
  name: string,
  options: FormInputOptions<FormNumberOptions>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: FormNumberComponent,
  contentContext: {
    config: options.config,
    options: {
      placeholder: options.config?.label,
      ...options.options,
    },
  } as Partial<FormNumberComponent>,
  layout: options.layout,
});

export const formDataText = (
  name: string,
  options: FormInputOptions<CrmFormTextareaOptions>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormTextareaComponent,
  contentContext: {
    config: options.config,
    options: {
      placeholder: options.config?.label,
      ...options.options,
    },
  } as Partial<CrmFormTextareaComponent>,
  layout: options.layout,
});

export const formDataCheckbox = (
  name: string,
  options: FormInputOptions<CrmFormCheckboxOptions>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormCheckboxComponent,
  contentContext: {
    config: options.config,
    options: options.options,
  } as Partial<CrmFormCheckboxComponent>,
  layout: options.layout,
  visible$: options.visible$,
});

export const formDataCheckboxGroup = (
  name: string,
  options: FormInputOptions<CrmFormCheckboxGroupOptions>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormCheckboxGroupComponent,
  contentContext: {
    config: options.config,
    options: options.options,
  } as Partial<CrmFormCheckboxGroupComponent>,
  layout: options.layout,
  visible$: options.visible$,
});

export const formDataSelect = <Value, Data>(
  name: string,
  options: FormInputOptions<CrmFormSelectOptions<Value, Data>>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormSelectComponent,
  contentContext: {
    config: options.config,
    options: {
      placeholder: options.config?.label,
      ...options.options,
    },
  } as Partial<CrmFormSelectComponent>,
  layout: options.layout,
  visible$: options.visible$,
});

export const formDataDate = (
  name: string,
  options: FormInputOptions<CrmFormDateOptions>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormDateComponent,
  contentContext: {
    config: options.config,
    options: {
      placeholder: options.config?.label,
      format: 'dd.MM.yyyy',
      ...options.options,
    },
  } as Partial<CrmFormDateComponent>,
  layout: options.layout,
  visible$: options.visible$,
});

export const formDataGallery = (
  name: string,
  options: FormInputOptions<CrmFormGalleryComponent['options']>,
): CrmFormFragment => ({
  name,
  type: 'data-input',
  content: CrmFormGalleryComponent,
  contentContext: {
    config: options.config,
    options: options.options,
  } as Partial<CrmFormGalleryComponent>,
  layout: options.layout,
});
