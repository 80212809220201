import { inject, Injectable } from '@angular/core';
import { CrmChangelogModalTrigger } from 'common-module/changelog';
import { race } from 'rxjs';

import { UserApiService } from '~/api/user/user-api.service';

@Injectable()
export class ChangelogModalTrigger extends CrmChangelogModalTrigger {
  constructor() {
    super();

    const { profile$, login$ } = inject(UserApiService);
    race(profile$, login$).subscribe(this.trigger$);
  }
}
