import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { CrmModalService } from 'common-module/modal';
import { EMPTY, map, Observable, of, tap, throwError } from 'rxjs';

import { environment } from '~/environments/environment';
import { WrongDomainComponent } from '~/shared/modal/domain/wrong-domain.component';
import { CurrentClinicService } from '~/shared/services/current-clinic.service';
import { CurrentClinic } from '~/shared/types/current-clinic';
import { catchAndHandleErrorFactory } from '~/shared/utils/error/catch-and-handle-error.factory';

import { DocumentsApiService } from '../documents/documents-api.service';

import { ClinicModel } from './clinic.model';
import { CreateClinicDto } from './create-clinic.dto';
import { UpdateClinicDto } from './update-clinic.dto';

@Injectable({ providedIn: 'root' })
export class ClinicsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'clinics',
  })
  private readonly endpoint!: CrmEndpoint<ClinicModel>;

  private currentClinic = inject(CurrentClinicService);
  private documentsApiService = inject(DocumentsApiService);
  private modal = inject(CrmModalService);
  private catchAndHandleError = catchAndHandleErrorFactory();

  getProfile(): Observable<CurrentClinic> {
    try {
      return of(this.currentClinic.clinic());
    } catch (_) {
      return this.endpoint.request<ClinicModel>('GET', 'profile').pipe(
        map((clinic) => {
          this.currentClinic.setCurrentClinic(clinic);
          this.checkDomain(clinic);
          return this.currentClinic.clinic();
        }),
        this.catchAndHandleError({
          $: () => EMPTY,
          errorMessage$: 'errors.clinics.profile',
        }),
      );
    }
  }

  getClinic(id: string) {
    return this.endpoint.read(id).pipe(
      this.catchAndHandleError({
        $: (err) => throwError(() => err),
        errorMessage$: { message: 'errors.clinics.get', context: { id } },
      }),
    );
  }

  listClinics(params?: CrmDictionary) {
    return this.endpoint.list({ params });
  }

  createClinic(body: CreateClinicDto) {
    return this.endpoint.create(body, { params: { template: '' } });
  }

  updateProfile(body: UpdateClinicDto): Observable<ClinicModel> {
    return this.endpoint
      .request<ClinicModel>('POST', 'profile', { body })
      .pipe(tap((clinic) => this.currentClinic.setCurrentClinic(clinic)));
  }

  updateClinic(id: string, body: UpdateClinicDto): Observable<ClinicModel> {
    return this.endpoint.update(id, body).pipe(
      tap((clinic) => {
        if (clinic._id === this.currentClinic.clinic().id) {
          this.currentClinic.setCurrentClinic(clinic);
        }
      }),
    );
  }

  getLogoUrl(clinic?: ClinicModel): Observable<string> {
    const logoUrl = (logo?: string) => {
      if (!logo) {
        return '/assets/images/logo-vets-default.svg';
      }

      return this.documentsApiService.getResourceUrl(logo);
    };

    if (clinic) {
      return of(logoUrl(clinic.meta.logo));
    }

    return this.getProfile().pipe(map((c) => logoUrl(c.logo)));
  }

  private checkDomain(clinic: ClinicModel) {
    if (clinic.meta.domain !== (environment.domain ?? location.hostname)) {
      this.modal.create({
        nzContent: WrongDomainComponent,
        nzData: {
          clinic: `${location.protocol}//${clinic.meta.domain}`,
          current: `${location.protocol}//${location.hostname}`,
        },
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: null,
      });
    }
  }
}
