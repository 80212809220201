import { Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { omit } from 'lodash-es';
import { Observable, of, switchMap, throwError } from 'rxjs';

import { catchAndHandleErrorFactory } from '~/shared/utils/error/catch-and-handle-error.factory';

import { EventAggregation } from './event.aggregation';
import { CreateEventModel, EventModel, UpdateEventModel } from './event.model';
import { EventType } from './event.type';

@Injectable({ providedIn: 'root' })
export class EventsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'principals',
  })
  protected readonly endpoint!: CrmEndpoint<unknown>;

  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'calendars',
  })
  protected readonly calendarsEndpoint!: CrmEndpoint<unknown>;

  private readonly catchAndHandleError = catchAndHandleErrorFactory();

  list<Event extends EventModel>(options: {
    type: EventType;
    principal: string;
    params?: CrmDictionary;
  }) {
    const { params, principal, type } = options;

    return this.endpoint
      .request<Event[]>('GET', this.buildUrl(principal, type), {
        responseType: 'json',
        params,
      })
      .pipe(
        this.catchAndHandleError({
          $: () => of<Event[]>([]),
          errorMessage$: 'errors.events.list',
        }),
      );
  }

  aggregate<Model extends EventModel>({
    users,
    type,
    params,
  }: {
    type: EventType;
    users: string[];
    params?: CrmDictionary;
  }) {
    return this.calendarsEndpoint
      .request<
        EventAggregation<Model>
      >('POST', [type, 'aggregate'].join('/'), { responseType: 'json', body: { users }, params })
      .pipe(
        this.catchAndHandleError({
          $: () => of<EventAggregation<Model>>({}),
          errorMessage$: 'errors.events.aggregate',
        }),
      );
  }

  get<Event extends EventModel>(options: {
    id?: string;
    type: EventType;
    principal: string;
    params?: CrmDictionary;
  }): Observable<Event> {
    const { id, params, principal, type } = options;

    if (!id) {
      return throwError(() => new Error('id must be a string'));
    }

    return this.endpoint
      .request<Event[]>('GET', this.buildUrl(principal, type), {
        responseType: 'json',
        params: { ...params, uid: id },
      })
      .pipe(
        switchMap((events) => {
          const event = events[0];

          if (!event) {
            return throwError(() => new Error('Unknown event'));
          }

          return of(event);
        }),
        this.catchAndHandleError({
          $: (err) => throwError(() => err),
          errorMessage$: { message: 'errors.events.get', context: { id } },
        }),
      );
  }

  create<Event extends EventModel>(options: {
    type: EventType;
    principal: string;
    body: CreateEventModel<Event>;
  }) {
    const { type, principal, body } = options;

    return this.endpoint
      .request<{ events: Event[] }>('POST', this.buildUrl(principal, type), {
        responseType: 'json',
        body: {
          ...omit(body, ['uid', 'href', 'dtStamp']),
          rrule: body.rrule ? omit(body.rrule, 'dtstart') : null,
        },
      })
      .pipe(
        switchMap(({ events }) => {
          const event = events[0];

          if (!event) {
            return throwError(() => new Error('Unknown event'));
          }

          return of(event);
        }),
        this.catchAndHandleError({
          $: (err) => throwError(() => err),
          errorMessage$: 'errors.events.create',
        }),
      );
  }

  update<Event extends EventModel>(options: {
    id: string;
    type: EventType;
    principal: string;
    body: UpdateEventModel<Event>;
  }): Observable<Event> {
    const { type, principal, body, id } = options;

    return this.endpoint
      .request<{ events: Event[] }>(
        'PUT',
        [this.buildUrl(principal, type), id].join('/'),
        {
          responseType: 'json',
          body: {
            ...omit(body, ['href', 'dtStamp']),
            rrule: body.rrule ? omit(body.rrule, 'dtstart') : null,
          },
        },
      )
      .pipe(
        switchMap(({ events }) => {
          const event = events[0];

          if (!event) {
            return throwError(() => new Error('Unknown event'));
          }

          return of(event);
        }),
        this.catchAndHandleError({
          $: (err) => throwError(() => err),
          errorMessage$: { message: 'errors.events.update', context: { id } },
        }),
      );
  }

  changeEventStatus<Event extends EventModel>({
    type,
    status,
    event,
    principal,
  }: {
    type: EventType;
    event: Event;
    status: Event['status'];
    principal: string;
  }) {
    return this.update<Event>({
      body: { ...event, status } as UpdateEventModel<Event>,
      principal,
      type,
      id: event.uid,
    });
  }

  private buildUrl(principal: string, type: EventType) {
    return [principal, 'calendars', type, 'events'].join('/');
  }
}
