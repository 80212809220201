import { AbstractControl, FormGroup } from '@angular/forms';
import { CrmForm, CrmFormProvider } from 'common-module/form';
import { filter, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { InterfaceToGroup } from '~/shared/types/interface-to-group';
import { controlChangeWithDefault } from '~/shared/utils/form/control-change-with-default';

export type Form<FormData> = Omit<CrmForm<FormData>, 'form'> & {
  form: FormGroup<InterfaceToGroup<FormData>>;
};

export class FormProvider<Input, FormData = Input> extends CrmFormProvider<
  Input,
  FormData
> {
  override crmForm!: Form<FormData>;
  override initialized$!: Subject<Form<FormData>>;

  override get form(): Form<FormData>['form'] {
    return super.form;
  }

  override get formValue(): Form<FormData>['form']['value'] {
    return super.formValue;
  }

  override get formRawValue(): ReturnType<
    Form<FormData>['form']['getRawValue']
  > {
    return super.formRawValue;
  }

  override formInitialized(form: Form<FormData>) {
    super.formInitialized(form);
  }

  protected valueChanges<Control extends AbstractControl>(
    control: Control,
    options?: { omitDefault?: true },
  ) {
    const getObs$ = () => {
      if (options?.omitDefault) {
        return control.valueChanges;
      }

      return controlChangeWithDefault(control);
    };

    return getObs$().pipe(
      filter(() => !this.isLoading),
      debounceTime(0),
      takeUntil(this.destroy$),
    );
  }
}
