import { Routes } from '@angular/router';
import { CrmChangelogListComponent } from 'common-module/changelog';
import { CrmLayoutV2OutletComponent } from 'common-module/layout-v2';
import { CrmLoginHandleSuccessToken } from 'common-module/auth';

import { canDisplay, hasAnyRole } from '~/api/user/user.guards';
import { UserPermissionKey } from '~/api/user/user.permissions';
import { loginHandleSuccess } from '~/crm/auth/login/login-handle-success';
import { profilesLoaded } from '~/shared/services/profiles.service';

import { RoutesConfig } from './config/routes.config';

export const appRoutes: Routes = [
  {
    path: RoutesConfig.AUTH,
    providers: [
      {
        provide: CrmLoginHandleSuccessToken,
        useValue: loginHandleSuccess,
      },
    ],
    loadChildren: () => import('common-module/auth').then((m) => m.authRoutes),
  },
  {
    path: '',
    canMatch: [profilesLoaded],
    children: [
      {
        path: RoutesConfig.ATTENDANCE,
        canMatch: [hasAnyRole(['attendance'])],
        loadChildren: () =>
          import('~/features/attendance/attendance.module').then(
            (m) => m.AttendanceModule,
          ),
      },
      {
        path: RoutesConfig.SELF_SERVICE,
        canMatch: [hasAnyRole(['selfservice'])],
        loadChildren: () =>
          import('~/features/self-service/self-service.module').then(
            (m) => m.SelfServiceModule,
          ),
      },
      {
        path: RoutesConfig.SIGNATURE,
        canMatch: [hasAnyRole(['signature'])],
        loadChildren: () =>
          import('~/features/signature/signature.module').then(
            (m) => m.SignatureModule,
          ),
      },
      {
        path: '',
        component: CrmLayoutV2OutletComponent,
        canMatch: [hasAnyRole(['staff'])],
        children: [
          {
            path: RoutesConfig.CHANGELOG,
            component: CrmChangelogListComponent,
          },
          {
            path: RoutesConfig.DASHBOARD,
            canMatch: [canDisplay(UserPermissionKey.DASHBOARD)],
            loadChildren: () =>
              import('~/features/dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
              ),
          },
          {
            path: RoutesConfig.CLINICS,
            canMatch: [canDisplay(UserPermissionKey.CLINICS)],
            loadChildren: () =>
              import('~/features/clinics/clinics.module').then(
                (m) => m.ClinicsModule,
              ),
          },
          {
            path: RoutesConfig.CALENDAR,
            canMatch: [canDisplay(UserPermissionKey.CALENDAR)],
            loadChildren: () =>
              import('~/features/appointments/appointments.module').then(
                (m) => m.AppointmentsModule,
              ),
          },
          {
            path: RoutesConfig.CUSTOMERS,
            canMatch: [canDisplay(UserPermissionKey.CUSTOMERS)],
            loadChildren: () =>
              import('~/features/customers/customers.module').then(
                (m) => m.CustomersModule,
              ),
          },
          {
            path: RoutesConfig.PATIENTS,
            canMatch: [canDisplay(UserPermissionKey.PATIENTS)],
            loadChildren: () =>
              import('~/features/patients/patients.module').then(
                (m) => m.PatientsModule,
              ),
          },
          {
            path: RoutesConfig.RECORDS,
            canMatch: [canDisplay(UserPermissionKey.RECORDS)],
            loadChildren: () =>
              import('~/features/medical/medical.module').then(
                (m) => m.MedicalModule,
              ),
          },
          {
            path: RoutesConfig.TEST_RESULT,
            canMatch: [canDisplay(UserPermissionKey.TEST_RESULTS)],
            loadChildren: () =>
              import('~/features/laboratory/laboratory.module').then(
                (m) => m.LaboratoryModule,
              ),
          },
          {
            path: RoutesConfig.RECORD_TEMPLATES,
            canMatch: [canDisplay(UserPermissionKey.RECORD_TEMPLATES)],
            loadChildren: () =>
              import(
                '~/features/medical-templates/medical-templates.module'
              ).then((m) => m.MedicalTemplatesModule),
          },
          {
            path: RoutesConfig.SEGMENTS,
            canMatch: [canDisplay(UserPermissionKey.SEGMENTS)],
            loadChildren: () =>
              import('~/features/segments/segments.module').then(
                (m) => m.SegmentsModule,
              ),
          },
          {
            path: RoutesConfig.SHIFT_PLANNER,
            canMatch: [canDisplay(UserPermissionKey.SHIFT_PLANNER)],
            loadChildren: () =>
              import('~/features/shift-planner/shift-planner.module').then(
                (m) => m.ShiftPlannerModule,
              ),
          },
          {
            path: RoutesConfig.TIME_TRACKER,
            canMatch: [canDisplay(UserPermissionKey.TIME_TRACKER)],
            loadChildren: () =>
              import('~/features/time-tracker/time-tracker.module').then(
                (m) => m.TimeTrackerModule,
              ),
          },
          {
            path: RoutesConfig.INVOICES,
            canMatch: [canDisplay(UserPermissionKey.INVOICES)],
            loadChildren: () =>
              import('~/features/invoices/invoices.module').then(
                (m) => m.InvoicesModule,
              ),
          },
          {
            path: RoutesConfig.DRAFTS,
            canMatch: [canDisplay(UserPermissionKey.DRAFTS)],
            loadChildren: () =>
              import('~/features/shopping-carts/shopping-carts.module').then(
                (m) => m.ShoppingCartsModule,
              ),
          },
          {
            path: RoutesConfig.QUOTES,
            canMatch: [canDisplay(UserPermissionKey.QUOTES)],
            loadChildren: () =>
              import('~/features/quotes/quotes.module').then(
                (m) => m.QuotesModule,
              ),
          },
          {
            path: RoutesConfig.REPORTS,
            canMatch: [canDisplay(UserPermissionKey.REPORTS)],
            loadChildren: () =>
              import('~/features/reports/reports.module').then(
                (m) => m.ReportsModule,
              ),
          },
          {
            path: RoutesConfig.TANGIBLE,
            canMatch: [canDisplay(UserPermissionKey.ITEMS)],
            loadChildren: () =>
              import('~/features/tangible/tangible.module').then(
                (m) => m.TangibleModule,
              ),
          },
          {
            path: RoutesConfig.INTANGIBLE,
            canMatch: [canDisplay(UserPermissionKey.ITEMS)],
            loadChildren: () =>
              import('~/features/intangible/intangible.module').then(
                (m) => m.IntangibleModule,
              ),
          },
          {
            path: RoutesConfig.ALL_ITEMS,
            canMatch: [canDisplay(UserPermissionKey.ITEMS)],
            loadChildren: () =>
              import('~/features/all-items/all-items.module').then(
                (m) => m.AllItemsModule,
              ),
          },
          {
            path: RoutesConfig.BUNDLES,
            canMatch: [canDisplay(UserPermissionKey.BUNDLES)],
            loadChildren: () =>
              import('~/features/bundles/bundles.module').then(
                (m) => m.BundlesModule,
              ),
          },
          {
            path: RoutesConfig.DRAFT_TEMPLATES,
            canMatch: [canDisplay(UserPermissionKey.DRAFT_TEMPLATES)],
            loadChildren: () =>
              import(
                '~/features/shopping-carts-templates/shopping-carts-templates.module'
              ).then((m) => m.ShoppingCartsTemplatesModule),
          },
          {
            path: RoutesConfig.ROOMS,
            canMatch: [canDisplay(UserPermissionKey.ROOMS)],
            loadChildren: () =>
              import('~/features/rooms/rooms.module').then(
                (m) => m.RoomsModule,
              ),
          },
          {
            path: RoutesConfig.EQUIPMENT,
            canMatch: [canDisplay(UserPermissionKey.EQUIPMENT)],
            loadChildren: () =>
              import('~/features/equipment/equipment.module').then(
                (m) => m.EquipmentModule,
              ),
          },
          {
            path: RoutesConfig.STAFF,
            canMatch: [canDisplay(UserPermissionKey.STAFF)],
            loadChildren: () =>
              import('~/features/staff/staff.module').then(
                (m) => m.StaffModule,
              ),
          },
          {
            path: RoutesConfig.CLINIC_SETTINGS,
            canMatch: [canDisplay(UserPermissionKey.CLINIC_SETTINGS)],
            loadChildren: () =>
              import('~/features/clinic-settings/clinic-settings.module').then(
                (m) => m.ClinicSettingsModule,
              ),
          },
          {
            path: RoutesConfig.DOCUMENT_TEMPLATES,
            canMatch: [canDisplay(UserPermissionKey.DOCUMENT_TEMPLATES)],
            loadChildren: () =>
              import(
                '~/features/document-templates/document-templates.module'
              ).then((m) => m.DocumentTemplatesModule),
          },
          {
            path: RoutesConfig.EMAIL_TEMPLATES,
            canMatch: [canDisplay(UserPermissionKey.EMAIL_TEMPLATES)],
            loadChildren: () =>
              import('~/features/email-templates/email-templates.module').then(
                (m) => m.EmailTemplatesModule,
              ),
          },
          {
            path: '**',
            redirectTo: RoutesConfig.DASHBOARD,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: RoutesConfig.AUTH,
  },
];
