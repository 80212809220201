import { inject, Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserApiService } from '~/api/user/user-api.service';
import { ClinicsApiService } from '~/api/clinics/clinics-api.service';

import { TitleService } from './title.service';

@Injectable({ providedIn: 'root' })
export class ProfilesService {
  private user = inject(UserApiService);
  private clinic = inject(ClinicsApiService);
  private title = inject(TitleService);

  profilesLoaded() {
    return this.user.user$.pipe(
      switchMap(() => this.clinic.getProfile()),
      tap(({ name }) => this.title.setClinicName(name)),
      map((clinic) => !!clinic),
    );
  }
}

export const profilesLoaded = () => inject(ProfilesService).profilesLoaded();
